export default {
  GET(state, data) {
    state.customer = { ...data }
  },
  LIST(state, data) {
    state.customers = data
  },
  SET_FORM(state, data) {
    state.customerForm = { ...data }
  },
  SET_BUSINESS_ENTITY_DEFAULTS_FORM(state, data) {
    state.BusinessEntityDefaultsForm = { ...data }
  },
  SET_CONTACT_DEFAULTS_FORM(state, data) {
    state.ContactDefaultsForm = { ...data }
  },
  SET_ADDRESS_DEFAULTS_FORM(state, data) {
    state.AddressDefaultsForm = { ...data }
  },
  SET_CUSTOMER_VERIFICATION_DEFAULTS_FORM(state, data) {
    state.CustomerVerificationDefaultsForm = { ...data }
  },
  SET_SEARCH_DEFAULTS_FORM(state, data) {
    state.SearchDefaultsForm = { ...data }
  },
}
