export default {
  customer: {},
  customerStatus: null,
  customers: [],
  CSVTemplate: null,
  primaryContactInformationForm: {},
  primaryAddressInformationForm: {},
  accountParametersForm: {},
  moreContactInformationForm: {},
  export: {},
  exportPath: 'customers/csv-export',
  exportPathTemplate: 'customers/csv-template',
  import: {},
  exportImportButtonItems: [
    {
      btnTitle: 'Customer information',
      actionType: 'customer_information',
    },
    {
      btnTitle: 'Customer addresses',
      actionType: 'customer_addresses',
    },
    {
      btnTitle: 'Customer contacts',
      actionType: 'customer_contacts',
    },
  ],
  defaultDataCustomer: null,
}
