export default {
  customer: {},
  customers: [],
  customerForm: {},
  BusinessEntityDefaultsForm: {},
  ContactDefaultsForm: {},
  AddressDefaultsForm: {},
  CustomerVerificationDefaultsForm: {},
  SearchDefaultsForm: {},
}
